/* eslint-disable */

import { createApp } from "vue";

import titleMixin from "./mixins/titleMixin";

// Import PrimeVUE CSS styles:
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

// Import PrimeVUE components:
import PrimeVue from "primevue/config";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import Chart from "primevue/chart"; // npm install chart.js
import Breadcrumb from "primevue/breadcrumb";
import Button from "primevue/button";
import Card from "primevue/card";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Message from "primevue/message";
import Skeleton from "primevue/skeleton";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import Tag from "primevue/tag";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import MultiSelect from "primevue/multiselect";
import ProgressSpinner from "primevue/progressspinner";
import Menu from "primevue/menu";
import Dialog from "primevue/dialog";
import Chip from "primevue/chip";
import DynamicDialog from "primevue/dynamicdialog";
import OverlayPanel from "primevue/overlaypanel";
import InlineMessage from "primevue/inlinemessage";
import Textarea from "primevue/textarea";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueMatomo from "vue-matomo";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(VueMatomo, {
  host: "http://www.statistical.engineering/matomo_jFi/",
  siteId: 1,
  trackerFileName: "matomo",
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
});
app.mixin(titleMixin);
app.component("ColorPicker", ColorPicker);
app.component("Chips", Chips);
app.component("Calendar", Calendar);
app.component("Chart", Chart);
app.component("Breadcrumb", Breadcrumb);
app.component("InputText", InputText);
app.component("Button", Button);
app.component("Card", Card);
app.component("Toast", Toast);
app.component("Message", Message);
app.component("Skeleton", Skeleton);
app.component("Checkbox", Checkbox);
app.component("Dropdown", Dropdown);
app.component("InputSwitch", InputSwitch);
app.component("Tag", Tag);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("Avatar", Avatar);
app.component("AvatarGroup", AvatarGroup);
app.component("MultiSelect", MultiSelect);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Menu", Menu);
app.component("Dialog", Dialog);
app.component("Chip", Chip);
app.component("DynamicDialog", DynamicDialog);
app.component("OverlayPanel", OverlayPanel);
app.component("InlineMessage", InlineMessage);
app.component("Textarea", Textarea);
app.mount("#app");

// Now you can access piwik api in components through
this.$matomo;

// or
window._paq.push;

// or through
window.Piwik.getTracker;
