import { createStore } from "vuex";

export default createStore({
  state: {
    welcome_screen: true,
    table_content: [],
  },
  mutations: {
    SET_WELCOME_SCREEN(state, value) {
      state.welcome_screen = value;
    },
    SET_TABLE_CONTENT(state, value) {
      state.table_content = value;
    },
  },
  actions: {
    setStartupScreen(context, value) {
      context.commit("SET_WELCOME_SCREEN", value);
    },
    setTableContent(context, value) {
      context.commit("SET_TABLE_CONTENT", value);
    },
  },
  getters: {
    getStartupScreen(state) {
      return state.welcome_screen;
    },
    getTableContent(state) {
      return state.table_content;
    },
  },
});
