<template>
  <div style="position: relative; height: 100%">
    <!-- Placeholder if no data: -->
    <div v-if="this.$store.getters.getStartupScreen == true">
      <welcome-graphics />
    </div>

    <div v-else-if="this.$store.getters.getTableContent.length > 0">
      <!-- Data table if data: -->
      <DataTable
        :value="this.$store.getters.getTableContent"
        :autoLayout="true"
        stripedRows
        :scrollable="true"
        scrollHeight="100%"
        responsiveLayout="scroll"
        class="main_table p-datatable-sm"
        showGridlines
      >
        <Column
          field="site_id"
          header="Site ID"
          style="min-width: 150px"
        ></Column>
        <Column
          field="site_name"
          header="Site Name"
          style="min-width: 300px"
        ></Column>
        <Column
          field="empty"
          header="Placement ID"
          style="min-width: 200px"
        ></Column>
        <Column
          field="placement_name"
          header="Placement Name"
          style="min-width: 400px"
        ></Column>
        <Column
          field="placement_compatibility"
          header="Placement Compatibility"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Placement Group Type"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Placement Group ID"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Primary Placement"
          style="min-width: 200px"
        ></Column>
        <Column
          field="payment_source"
          header="Payment Source"
          style="min-width: 200px"
        ></Column>
        <Column
          field="dimensions"
          header="Dimensions"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Placement Orientation"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Placement Duration"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Placement Publisher Specification"
          style="min-width: 200px"
        ></Column>
        <Column
          field="placement_tag_wrapping"
          header="Placement Tag Wrapping"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Placement Tag Wrapping Type"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Placement Tag Wrapping Measurement Mode"
          style="min-width: 200px"
        ></Column>
        <Column
          field="placement_status"
          header="Placement status"
          style="min-width: 200px"
        ></Column>
        <Column
          field="start_date"
          header="Start Date"
          style="min-width: 200px"
        ></Column>
        <Column
          field="end_date"
          header="End Date"
          style="min-width: 200px"
        ></Column>
        <Column
          field="cost_structure"
          header="Cost Structure"
          style="min-width: 200px"
        ></Column>
        <Column field="units" header="Units" style="min-width: 200px"></Column>
        <Column field="rate" header="Rate" style="min-width: 200px"></Column>
        <Column field="cost" header="Cost" style="min-width: 200px"></Column>
        <Column
          field="opt_this_placement_out_of_ad_blocking"
          header="Opt This Placement Out of Ad Blocking"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Placement Comments"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Content Category"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Placement Strategy"
          style="min-width: 200px"
        ></Column>
        <Column
          field="assignment_is_active"
          header="Assignment is Active"
          style="min-width: 200px"
        ></Column>
        <Column field="empty" header="Ad ID" style="min-width: 200px"></Column>
        <Column
          field="ad_name"
          header="Ad Name"
          style="min-width: 400px"
        ></Column>
        <Column
          field="ad_type"
          header="Ad Type"
          style="min-width: 200px"
        ></Column>
        <Column
          field="ad_is_active"
          header="Ad is Active"
          style="min-width: 200px"
        ></Column>
        <Column
          field="ad_is_archived"
          header="Ad is Archived"
          style="min-width: 200px"
        ></Column>
        <Column
          field="ad_start_date"
          header="Ad Start Time"
          style="min-width: 200px"
        ></Column>
        <Column
          field="ad_end_date"
          header="Ad End Time"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Ad Uses Default Landing Page"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Ad Landing Page ID"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Ad Landing Page Name"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Ad Click-Through URL"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Applied Impression Event Tag IDs"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Applied Click Event Tag ID"
          style="min-width: 200px"
        ></Column>
        <Column
          field="hard_cut_off"
          header="Hard Cut-Off"
          style="min-width: 200px"
        ></Column>
        <Column
          field="priority"
          header="Priority"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Impression Ratio"
          style="min-width: 200px"
        ></Column>
        <Column
          field="rotation_type"
          header="Rotation Type"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Weight Calculation Strategy"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Rotation Value"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Audience Segment Group Name"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Audience Segment Name"
          style="min-width: 200px"
        ></Column>
        <Column
          field="creative_id"
          header="Creative ID"
          style="min-width: 200px"
        ></Column>
        <Column
          field="creative_name"
          header="Creative Name"
          style="min-width: 400px"
        ></Column>
        <Column
          field="creative_type"
          header="Creative Type"
          style="min-width: 200px"
        ></Column>
        <Column
          field="creative_compatibility"
          header="Creative Compatibility"
          style="min-width: 200px"
        ></Column>
        <Column
          field="creative_start_date"
          header="Creative Start Date"
          style="min-width: 200px"
        ></Column>
        <Column
          field="creative_end_date"
          header="Creative End Date"
          style="min-width: 200px"
        ></Column>
        <Column
          field="creative_assignment_is_active"
          header="Creative Assignment is Active"
          style="min-width: 200px"
        ></Column>
        <Column
          field="creative_assignment_event_tags_enabled"
          header="Creative Assignment Event Tags Enabled"
          style="min-width: 200px"
        ></Column>
        <Column
          field="creative_uses_default_landing_page"
          header="Creative Uses Default Landing Page"
          style="min-width: 200px"
        ></Column>
        <Column
          field="landing_page_id"
          header="Creative Landing Page ID"
          style="min-width: 200px"
        ></Column>
        <Column
          field="landing_page_name"
          header="Creative Landing Page Name"
          style="min-width: 200px"
        ></Column>
        <Column
          field="empty"
          header="Creative Click-Through URL"
          style="min-width: 400px"
        ></Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import WelcomeGraphics from "@/components/WelcomeGraphics.vue";

export default {
  title: "AdOps Optimizer 2000",
  components: {
    WelcomeGraphics,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.main_table {
  height: calc(100vh - 70px);

  font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
}
</style>