<template>
  <div
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 300px;
      width: 300px;
    "
  >
    <div style="position: relative; height: 100%; width: 100%">
      <img
        src="@/assets/welcome_graphics_moon.svg"
        class="welcome_graphics_element moon"
      />
      <img
        src="@/assets/welcome_graphics_satellite.svg"
        class="welcome_graphics_element satellite"
      />
      <img
        src="@/assets/welcome_graphics_cloud_1.svg"
        class="welcome_graphics_element cloud_1"
      />
      <img
        src="@/assets/welcome_graphics_cloud_2.svg"
        class="welcome_graphics_element cloud_2"
      />
    </div>
  </div>
</template>


<script>
export default {};
</script>

<style scoped>
.welcome_graphics_element {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.moon {
  animation: rotate_clockwise 100s infinite linear;
}

.satellite {
  animation: rotate_counter_clockwise 180s infinite linear;
}

.cloud_1 {
  animation: rotate_counter_clockwise 260s infinite linear;
}

.cloud_2 {
  animation: rotate_clockwise 360s infinite linear;
}

@keyframes rotate_clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotate_counter_clockwise {
  from {
    transform: rotate(20deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>