<template>
  <!-- Pre-load image assets: -->
  <img
    src="@/assets/naming_convention_no_fonts.svg"
    style="height: 0px; width: 0px; opacity: 0; display: position: absolute; left: 0px; top: 0px; position: absolute; top: 0; left: 0;"
  />
  <img
    src="@/assets/example_creatives_no_fonts.svg"
    style="height: 0px; width: 0px; opacity: 0; display: position: absolute; left: 0px; top: 0px; position: absolute; top: 0; left: 0;"
  />
  <!-- End of pre-loading image assets. -->

  <div class="top_menu">
    <!--
    <p class="logotype">AdOps Optimizer 2000</p>
    -->
    <img src="@/assets/logotype.svg" style="height: 18px" class="logotype" />

    <Button
      label="Save"
      :disabled="this.file_batches.length == 0"
      class="p-button-text menu_button"
      icon="pi pi-save"
      iconPos="right"
      @click="display_saving_popup = true"
    />
    <Button
      label="Load"
      :disabled="this.savings == null"
      class="p-button-text menu_button"
      icon="pi pi-folder-open"
      iconPos="right"
      @click="display_load_saving_popup = true"
    />
    <Button
      label="Restart"
      :disabled="this.file_batches.length == 0"
      class="p-button-text menu_button"
      icon="pi pi-undo"
      iconPos="right"
      @click="restartWorkspace()"
    />

    <Button
      label="Download"
      :disabled="this.downloadable_sheet == null"
      class="p-button-success download_button"
      icon="pi pi-download"
      iconPos="right"
      @click="downloadSheet('xlsx', 'base64')"
    />
  </div>
  <div class="left_menu_and_main_content_wrapper">
    <div class="left_menu" @dragover.prevent @drop.prevent>
      <!-- File batch area: -->
      <div
        style="height: fit-content"
        v-for="(batch, key) in file_batches"
        :key="key"
      >
        <transition name="batch-card" appear>
          <div class="batch_card" v-if="batch.display == true">
            <p class="batch_header">
              <i class="pi pi-check-circle" style="margin-right: 5px" />
              {{ batch.name }}
              <i
                class="pi pi-trash"
                style="
                  float: right;
                  color: #ccae2e;
                  cursor: pointer;
                  margin-left: 10px;
                "
                @click="deleteBatch(key)"
              />
              <i
                class="pi pi-pencil"
                style="float: right; color: #ccae2e; cursor: pointer"
                @click="toggle"
              />
            </p>
            <p v-for="file in batch.files" :key="file" class="batch_file">
              <i
                class="pi pi-file-o"
                style="margin-right: 3px; font-size: 10px"
              />{{ file.filename }}
            </p>
          </div>
        </transition>
      </div>
      <!-- End of file batch area. -->
      <!-- File drop area: -->
      <div @drop="droppedFiles" class="left_menu_upload_area">
        <div class="upload_area_card">
          <div>
            <img
              src="@/assets/file-image.svg"
              style="width: 30px; opacity: 0.5"
            />
            <p style="margin: 5px; color: #888; font-weight: 700">
              Drop files here
            </p>
            <p class="drop_files_help_link" @click="display_help_popup = true">
              Drop what?
              <i
                class="pi pi-question-circle"
                style="position: relative; top: 1px; left: 3px"
              ></i>
            </p>
          </div>
        </div>
      </div>
      <!-- End of file drop area. -->
    </div>
    <div class="main_content"><router-view /></div>
  </div>

  <!-- Help popup: -->
  <Dialog
    header="Drop what?"
    v-model:visible="display_help_popup"
    class="add_batch_popup"
    :modal="true"
  >
    <p style="margin: 0 0 20px 0">
      Drag and drop your creatives, with this naming convention:
    </p>

    <img
      src="@/assets/naming_convention_no_fonts.svg"
      style="
        width: 250px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      "
    />

    <p style="margin: 40px 0 20px 0">Here are some examples:</p>

    <img
      src="@/assets/example_creatives_no_fonts.svg"
      style="
        width: 300px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 30px;
      "
    />

    <br />

    <Button
      label="OK"
      class="p-button"
      icon="pi pi-thumbs-up"
      iconPos="right"
      style="float: right; margin-right: 10px"
      @click="this.display_help_popup = false"
    />
  </Dialog>
  <!-- End of help popup. -->

  <!-- Saving popup: -->
  <Dialog
    header="Save"
    v-model:visible="display_saving_popup"
    class="add_batch_popup"
    :modal="true"
  >
    <p style="margin: 0 0 40px 0">
      Your savings can only be accessed from this device.
    </p>

    <div v-if="savings != null">
      <!-- Savings list: -->
      <p style="font-weight: 700; margin-top: 10px">Replace:</p>
      <!-- End of savings list -->

      <div
        v-for="saving in savings"
        class="load_savings_row"
        @click="replaceSaving(saving.updated_at)"
      >
        <div>
          {{ saving.name }}
        </div>
        <div style="text-align: right">
          {{ formatDate(saving.updated_at) }}
        </div>
      </div>
    </div>

    <!-- Savings list: -->
    <p style="font-weight: 700; margin-top: 40px">New:</p>
    <!-- End of savings list -->

    <InputText
      style="width: 300px"
      type="text"
      v-model="text_input_saving_name"
      class="text_input_field"
      placeholder="Name"
    />
    <Button
      label="Save"
      :disabled="this.file_batches.length == 0"
      class="p-button"
      icon="pi pi-save"
      iconPos="right"
      style="margin-left: 30px; position: relative; top: -1px"
      @click="createNewSaving(text_input_saving_name)"
    />
  </Dialog>
  <!-- End of saving popup. -->

  <!-- Load saving popup: -->
  <Dialog
    header="Load saving"
    v-model:visible="display_load_saving_popup"
    class="add_batch_popup"
    :modal="true"
  >
    <!-- Savings list: -->
    <p style="font-weight: 700; margin-top: 10px">Savings:</p>

    <div
      v-for="saving in savings"
      class="load_savings_row"
      @click="loadSaving(saving.data)"
    >
      <div>
        {{ saving.name }}
      </div>
      <div style="text-align: right">
        {{ formatDate(saving.updated_at) }}
      </div>
    </div>

    <!-- End of savings list -->
  </Dialog>
  <!-- End of load saving popup. -->

  <!-- Batch popup: -->
  <Dialog
    header="New creative batch"
    v-model:visible="display_add_batch_popup"
    class="add_batch_popup"
    :modal="true"
  >
    <!-- Warnings: -->
    <Message
      v-if="this.warning_creatives_sharing_dimension == true"
      severity="warn"
      :closable="false"
      >There are several creatives with the same dimension</Message
    >
    <!-- End of warnings. -->

    <!-- General: -->
    <p style="font-weight: 700; margin-top: 10px">General:</p>

    <!-- Select site: -->
    <div class="text_input_wrapper" style="width: 600px">
      <Dropdown
        v-model="selected_site"
        :options="sites"
        optionLabel="label"
        placeholder="Site"
        class="dropdown_input_field"
        :filter="true"
        style="width: 300px"
      >
        <template #option="slotProps">
          <div class="country-item">
            <div
              style="
                font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
              "
            >
              {{ slotProps.option.label }}
            </div>
          </div>
        </template>
      </Dropdown>
    </div>
    <!-- End of select site. -->

    <!-- Select weight calculation strategy: -->
    <!-- As per a meeting on 2022-07-14:
      The second dropdown ("Weight calculation strategy") in the new batch popup is not needed.
      However, the dropdown will probably be needed when editing a campaign later on.
    <div class="text_input_wrapper" style="width: 600px">
      <Dropdown
        v-model="selected_weight_calculation_strategy"
        :options="weight_calculation_strategies"
        optionLabel="label"
        placeholder="Weight calculation strategy"
        class="dropdown_input_field"
        style="width: 300px"
      >
        <template #option="slotProps">
          <div class="country-item">
            <div
              style="
                font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
              "
            >
              {{ slotProps.option.label }}
            </div>
          </div>
        </template>
      </Dropdown>
    </div>
    -->
    <!-- End of select weight calculation strategy. -->

    <!-- Landing page: -->
    <p style="font-weight: 700; margin-top: 10px">Landing page:</p>
    <div class="text_input_wrapper" style="width: 600px">
      <Textarea
        v-model="text_input_landing_page_id"
        v-if="text_input_landing_page_id == null"
        :autoResize="true"
        rows="5"
        style="width: 600px"
        placeholder="Paste text from Campaign Manager"
      />
      <div v-else>
        <div
          style="width: 100%; display: grid; grid-template-columns: 120px auto"
        >
          <div>
            <Button
              label="Reset"
              class="p-button-outlined"
              icon="pi pi-trash"
              iconPos="right"
              @click="resetLandingPageIdText()"
            />
          </div>
          <div>
            <Chip
              :label="`Label: ${this.landing_page_name}`"
              icon="pi pi-tag"
              class="mr-2 mb-2"
              style="background: #d4e4ff"
            />
            <br />
            <Chip
              :label="`ID: ${this.landing_page_id}`"
              icon="pi pi-key"
              class="mr-2 mb-2"
              style="background: #d4e4ff"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Old implementation:
    <div class="text_input_wrapper" style="width: 600px">
      <span class="p-input-icon-left" style="width: 600px">
        <i class="pi pi-link" style="color: #dbdffd" />
        <InputText
          style="width: 600px"
          type="text"
          v-model="text_input_landing_page"
          class="text_input_field"
          placeholder="Landing page"
        />
      </span>
    </div>
    -->
    <!-- End of landing page. -->

    <!-- Dates: -->
    <p style="font-weight: 700; margin-top: 40px">Dates:</p>

    <!-- As per meeting on 2022-07-14, "Ad start/end" shall be determined by "Start/End date" (col S, T).
    <div style="display: grid; grid-template-columns: 1fr 1fr">
      <div>
        <label for="range">Ad start</label><br />
        <Calendar
          v-model="selected_ad_start_date"
          :manualInput="true"
          :showTime="true"
          :showSeconds="false"
          dateFormat="yy-mm-dd"
          style="padding: 10px; width: 300px"
          inputStyle="font-family: customFont, Avenir, Helvetica, Arial, sans-serif"
        />
      </div>
      <div>
        <label for="range">Ad end</label><br />
        <Calendar
          v-model="selected_ad_end_date"
          :manualInput="true"
          :showTime="true"
          :showSeconds="false"
          dateFormat="yy-mm-dd"
          style="padding: 10px; width: 300px"
          inputStyle="font-family: customFont, Avenir, Helvetica, Arial, sans-serif"
        />
      </div>
    </div>
    <br />
    -->

    <div style="display: grid; grid-template-columns: 1fr 1fr">
      <div>
        <label for="range">Creative start</label><br />
        <Calendar
          v-model="selected_creative_start_date"
          :manualInput="true"
          :showTime="true"
          :showSeconds="false"
          dateFormat="yy-mm-dd"
          style="padding: 10px; width: 300px"
          inputStyle="font-family: customFont, Avenir, Helvetica, Arial, sans-serif"
        />
      </div>
      <div>
        <label for="range">Creative end</label><br />
        <Calendar
          v-model="selected_creative_end_date"
          :manualInput="true"
          :showTime="true"
          :showSeconds="false"
          dateFormat="yy-mm-dd"
          style="padding: 10px; width: 300px"
          inputStyle="font-family: customFont, Avenir, Helvetica, Arial, sans-serif"
        />
      </div>
    </div>
    <!-- End of dates. -->

    <!-- Dimensions list: -->
    <p style="font-weight: 700; margin-top: 40px">Dimensions:</p>
    <div
      style="
        margin: 20px 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        row-gap: 10px;
      "
    >
      <div
        v-for="dimension in this.file_batches.length == 0
          ? 0
          : this.file_batches[this.file_batches.length - 1].unique_dimensions"
        style="display: inline-block; margin-right: 10px"
      >
        <div
          style="
            width: 1em;
            height: 1em;
            display: inline-block;
            position: relative;
          "
        >
          <div :style="relativeDimension(dimension)"></div>
        </div>
        <p :style="dimensionTextStyling(dimension)">
          {{ dimension }}
        </p>
      </div>
    </div>
    <!-- End of dimensions list. -->

    <div style="display: grid; grid-template-columns: 1fr 50px 1fr">
      <div>
        <!-- Placement names: -->
        <p style="font-weight: 700; margin-top: 30px">Placement names:</p>

        <div
          class="text_input_wrapper"
          v-for="(placement, key) in Math.max(
            this.text_input_placements.length + 1,
            this.text_input_ad_names.length + 1
          )"
          :key="key"
        >
          <transition name="field-input" appear>
            <span class="p-input-icon-left">
              <i class="pi pi-window-minimize" style="color: #dbdffd" />
              <InputText
                type="text"
                v-model="text_input_placements[key]"
                class="text_input_field"
                style="width: 280px"
                :placeholder="`Placement ${key + 1}`"
                @input="textInputPlacementsChange(key)"
              />
            </span>
          </transition>
        </div>
        <!-- End of placement names. -->
      </div>

      <div>
        <!-- Placement arrow: -->
        <p style="font-weight: 700; margin-top: 30px; opacity: 0">.</p>

        <div
          class="text_input_wrapper"
          v-for="(placement, key) in Math.max(
            this.text_input_placements.length + 1,
            this.text_input_ad_names.length + 1
          )"
          :key="key"
        >
          <transition name="field-input" appear
            ><div style="height: 40px; width: 30px; position: relative">
              <i
                class="pi pi-arrows-h"
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
              ></i></div
          ></transition>
        </div>
        <!-- End of arrow. -->
      </div>

      <div>
        <!-- Ad names: -->
        <p style="font-weight: 700; margin-top: 30px">Ad names:</p>

        <div
          class="text_input_wrapper"
          v-for="(placement, key) in Math.max(
            this.text_input_placements.length + 1,
            this.text_input_ad_names.length + 1
          )"
          :key="key"
        >
          <transition name="field-input" appear>
            <span class="p-input-icon-left">
              <i class="pi pi-image" style="color: #dbdffd" />
              <InputText
                type="text"
                v-model="text_input_ad_names[key]"
                class="text_input_field"
                style="width: 280px"
                :placeholder="`Ad name ${key + 1}`"
              />
            </span>
          </transition>
        </div>
        <!-- End of ad names. -->
      </div>
    </div>

    <!-- Creative ID: -->
    <p style="font-weight: 700; margin-top: 40px">Creative IDs:</p>

    <!-- Paste text from Campaign Manager: -->
    <div class="text_input_wrapper" style="width: 600px">
      <Textarea
        v-model="text_input_creative_ids"
        v-if="text_input_creative_ids == null"
        :autoResize="true"
        rows="5"
        style="width: 600px"
        placeholder="Paste text from Campaign Manager"
      />
      <div v-else>
        <Button
          label="Reset"
          class="p-button-outlined"
          icon="pi pi-trash"
          iconPos="right"
          @click="resetCreativeIdsText()"
        />

        <ul>
          <li
            v-for="file in this.file_batches[this.file_batches.length - 1]
              .files"
            style="margin-bottom: 10px"
          >
            <p style="margin: 0; padding-right: 10px; display: inline-block">
              {{ file.filename }}
            </p>
            <!-- Tag: -->
            <Tag
              v-if="file.creative_id == null"
              class="mr-2"
              icon="pi pi-exclamation-triangle	"
              severity="danger"
              value="Missing creative ID"
              style="
                font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
                padding-right: 10px;
              "
              rounded
            ></Tag>
            <Tag
              v-else
              class="mr-2"
              icon="pi pi-check"
              severity="success"
              :value="file.creative_id"
              style="
                font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
                padding-right: 10px;
              "
              rounded
            ></Tag>
            <!-- End of tag. -->
          </li>
        </ul>
      </div>
    </div>
    <!-- End of select site. -->

    <!-- Bottom row: -->
    <div class="buttom_row">
      <Button
        label="Cancel"
        class="p-button-secondary p-button-text menu_button"
        icon="pi pi-times"
        iconPos="right"
        @click="cancelNewBatch()"
        style="margin-right: 20px"
      />
      <Button
        label="Save"
        class="p-button-success download_button"
        icon="pi pi-check"
        iconPos="right"
        :disabled="
          this.selected_site == null ||
          //this.selected_weight_calculation_strategy == null ||
          //this.text_input_landing_page == null ||
          //this.selected_ad_start_date == null ||
          //this.selected_ad_end_date == null ||
          this.selected_creative_start_date == null ||
          this.selected_creative_end_date == null ||
          this.text_input_placements.length == 0 ||
          this.text_input_ad_names.length == 0
        "
        @click="saveNewBatch()"
      />
    </div>
    <!-- End of bottom row. -->
  </Dialog>
  <!-- End of batch popup. -->

  <!-- Toast message: -->
  <template>
    <Toast position="bottom-center" style="opacity: 0.98" />
  </template>
  <!-- End of toast message. -->
</template>

<script>
var XLSX = require("xlsx");
import { read, utils, writeFileXLSX } from "xlsx";
const dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default {
  data() {
    return {
      savings: null,
      file_batches: [],
      accepted_dimensions: [
        "300x250",
        "320x50",
        "300x50",
        "728x90",
        "300x600",
        "336x280",
        "250x250",
        "320x100",
        "160x600",
        "468x60",
        "300x100",
        "970x250",
        "970x90",
        "320x480",
        "333x333",
        "200x200",
        "120x240",
        "120x600",
        "320x150",
        "240x400",
        "360x640",
        "320x568",
        "180x150",
        "300x300",
        "480x320",
        "250x300",
        "320x320",
        "640x360",
        "320x250",
        "600x300",
        "320x350",
        "125x125",
        "250x360",
        "400x240",
        "300x400",
        "320x160",
        "980x240",
        "620x891",
        "600x600",
        "300x150",
        "980x120",
        "600x862",
        "320x300",
        "250x600",
        "980x300",
        "320x400",
        "640x320",
        "320x240",
        "980x600",
        "468x400",
        "980x360",
        "980x400",
        "980x480",
        "320x80",
        "480x80",
        "300x480",
        "250x800",
        "640x640",
        "640x420",
        "468x600",
        "140x350",
        "200x600",
        "600x500",
      ],
      weight_calculation_strategies: [
        {
          label: "Even",
          value: "Even",
        },
        {
          label: "Custom",
          value: "Custom",
        },
        {
          label: "CTR",
          value: "CTR",
        },
        {
          label: "Optimized",
          value: "Optimized",
        },
      ],
      sites: [
        {
          label: "60plusbanken Sweden",
          site_name: "DBM BlueStep -806015437-60plusbanken Sweden 6305756",
          site_id: 10265217,
        },
        {
          label: "Bluebird Media",
          site_name:
            "DBM BlueBird - DQ&A - DV360 - SE 610544525-Bluebird Media",
          site_id: 6122493,
        },
        {
          label: "Bluebird Media Test",
          site_name:
            "DBM BlueBird - DQ&A - DV360 - SE 757360331-Bluebird Media Test",
          site_id: 7077058,
        },
        {
          label: "BlueStep Norway",
          site_name: "DBM BlueStep -20729703-BlueStep Norway 4609667",
          site_id: 8480730,
        },
        {
          label: "BlueStep Sweden",
          site_name: "DBM BlueStep 98171422-BlueStep 4511148",
          site_id: 8379346,
        },
        {
          label: "Eton",
          site_name:
            "DBM TP - Eton / Bluebird - DV360 - DQ&A - SE -1569147821-Eton 8430608",
          site_id: 8037570,
        },
        {
          label: "Hyatt EMEA",
          site_name: "Bluebird DV360 - EMEA",
          site_id: 8131215,
        },
        {
          label: "Hyvinvoinnin FI",
          site_name:
            "DBM BlueBird - DQ&A - DV360 - SE 1213596714-Hyvinvoinnin FI",
          site_id: 6051018,
        },
        {
          label: "Ideal of Sweden (DE)",
          site_name:
            "DBM TP - Idealofsweden - DQ&A - DV360 - DACH -103177389-IDEAL OF SWEDEN DV360 [DE]",
          site_id: 7085118,
        },
        {
          label: "Ideal of Sweden (NL)",
          site_name:
            "DBM TP - Idealofsweden - DQ&A - DV360 - DACH 1377436457-IDEAL OF SWEDEN DV360 [NL]",
          site_id: 7081793,
        },
        {
          label: "Ideal of Sweden (NO)",
          site_name:
            "DBM TP - Idealofsweden - DQ&A - DV360 - DACH 645047475-IDEAL OF SWEDEN DV360 [NO]",
          site_id: 7091296,
        },
        {
          label: "Ideal of Sweden (SE)",
          site_name:
            "DBM TP - Idealofsweden - DQ&A - DV360 - DACH -1918165111-IDEAL OF SWEDEN DV360 [SE]",
          site_id: 7072695,
        },
        {
          label: "Mathem",
          site_name:
            "DBM Mathem / Precis Digital 1759907026-MatHem",
          site_id: 6074281,
        },
        {
          label: "Nordiska Fönster",
          site_name:
            "DBM BlueBird - DQ&A - DV360 - SE -1949923641-Nordiska Fönster",
          site_id: 7485839,
        },
        {
          label: "Parfym SE",
          site_name: "DBM BlueBird - DQ&A - DV360 - SE 863697205-Parfym SE",
          site_id: 6624942,
        },
        {
          label: "Rajala (FI)",
          site_name: "DBM TP - Rajala - DQ&A - DV360 - FI -836487000-FI_Rajala",
          site_id: 6223033,
        },
        {
          label: "Rajala (SE)",
          site_name: "DBM TP - Rajala - DQ&A - DV360 - FI 1805514465-SE_Rajala",
          site_id: 6223027,
        },
        {
          label: "Soundtrack Your Brand",
          site_name:
            "DBM BlueBird - DQ&A - DV360 - SE 1008089183-Soundtrack Your Brand",
          site_id: 5710349,
        },
      ],
      downloadable_sheet: null,
      display_saving_popup: false,
      display_load_saving_popup: false,
      display_help_popup: false,
      text_input_saving_name: null,
      // Add new batch popup:
      display_add_batch_popup: false,
      warning_creatives_sharing_dimension: false,
      selected_site: {
        label: "Bluebird Media",
        site_name: "DBM BlueBird - DQ&A - DV360 - SE 610544525-Bluebird Media",
        site_id: 6122493,
      },
      selected_weight_calculation_strategy: "Custom",
      selected_ad_start_date: new Date(),
      selected_ad_end_date: new Date(),
      selected_creative_start_date: new Date(),
      selected_creative_end_date: new Date(),
      text_input_landing_page: "https://www.duckduckgo.com",
      text_input_placements: ["AlwaysOn"],
      text_input_ad_names: ["AlwaysOn"],
      text_input_creative_ids: null,
      text_input_landing_page_id: null,
      landing_page_id: null,
      landing_page_name: null,
    };
  },
  mounted() {
    this.loadSavingsFromLocalStorage();
    window.addEventListener("hashchange", function () {
      _paq.push(["setCustomUrl", "/" + window.location.hash.substr(1)]);
      _paq.push(["setDocumentTitle", "My New Title"]);
      _paq.push(["trackPageView"]);
    });
  },
  watch: {
    "file_batches.length": function () {
      this.updateTableContentInStore();
    },
    selected_ad_period: function (new_value) {
      if (this.selected_creative_period == null && new_value[1] != null)
        this.selected_creative_period = new_value;
    },
    "$store.getters.getTableContent": function () {
      // Prepare JavaScript object for download:
      // To prevent downloading when constructing the table:
      this.downloadable_sheet = null;
      // Loop table content in Store and prepare formatted sheet:
      let table_content = this.$store.getters.getTableContent;
      let formatted_table = [];
      table_content.forEach((row) => {
        formatted_table.push({
          "Error Message": "",
          "Site ID": row.site_id,
          "Site Name": row.site_name,
          "Placement ID": "",
          "Placement Name": row.placement_name,
          "Placement Compatibility": row.placement_compatibility,
          "Placement Group Type": "",
          "Placement Group ID": "",
          "Primary Placement": "",
          "Payment Source": row.primary_source,
          Dimensions: row.dimensions,
          "Placement Orientation": "",
          "Placement Duration": "",
          "Placement Publisher Specification": "",
          "Placement Tag Wrapping": row.placement_tag_wrapping,
          "Placement Tag Wrapping Type": "",
          "Placement Tag Wrapping Measurement Mode": "",
          "Placement status": row.placement_status,
          "Start Date": row.start_date,
          "End Date": row.end_date,
          "Cost Structure": row.cost_structure,
          Units: row.units,
          Rate: row.rate,
          Cost: row.cost,
          "Opt This Placement Out of Ad Blocking":
            row.opt_this_placement_out_of_ad_blocking,
          "Placement Comments": "",
          "Content Category": "",
          "Placement Strategy": "",
          "Assignment is Active": row.assignment_is_active,
          "Ad ID": "",
          "Ad Name": row.ad_name,
          "Ad Type": row.ad_type,
          "Ad is Active": row.ad_is_active,
          "Ad is Archived": row.ad_is_archived,
          "Ad Start Time": row.ad_start_date,
          "Ad End Time": row.ad_end_date,
          "Ad Uses Default Landing Page": "",
          "Ad Landing Page ID": "",
          "Ad Landing Page Name": "",
          "Ad Click-Through URL": "",
          "Applied Impression Event Tag IDs": "",
          "Applied Click Event Tag ID": "",
          "Hard Cut-Off": row.hard_cut_off,
          Priority: row.priority,
          "Impression Ratio": "",
          "Rotation Type": row.rotation_type,
          "Weight Calculation Strategy": "", // row.weight_calculation_strategy,
          "Rotation Value": "",
          "Audience Segment Group Name": "",
          "Audience Segment Name": "",
          "Creative ID": row.creative_id,
          "Creative Name": row.creative_name,
          "Creative Type": row.creative_type,
          "Creative Compatibility": row.creative_compatibility,
          "Creative Start Date": row.creative_start_date,
          "Creative End Date": row.creative_end_date,
          "Creative Assignment is Active": row.creative_assignment_is_active,
          "Creative Assignment Event Tags Enabled":
            row.creative_assignment_event_tags_enabled,
          "Creative Uses Default Landing Page":
            row.creative_uses_default_landing_page,
          "Creative Landing Page ID": row.landing_page_id,
          "Creative Landing Page Name": row.landing_page_name,
          "Creative Click-Through URL": "",
        });
      });
      // Store JavaScript object with formatted table content:
      this.downloadable_sheet = formatted_table;
    },
    text_input_creative_ids: function (new_value) {
      this.findCreativeIdsInText(new_value);
    },
    text_input_landing_page_id: function (new_value) {
      this.findLandingPageIdAndLabelInText(new_value);
    },
  },
  methods: {
    textInputPlacementsChange(index) {
      // Automatically populate the "ad name" input field:
      if (
        this.text_input_ad_names[index] == null ||
        this.text_input_placements[index].includes(
          this.text_input_ad_names[index]
        ) ||
        this.text_input_ad_names[index].includes(
          this.text_input_placements[index]
        )
      ) {
        this.text_input_ad_names[index] = this.text_input_placements[index];
      } else {
        console.log("Placement name and ad name are disconnected");
      }
    },
    loadSavingsFromLocalStorage() {
      // Load (and sort) savings from local storage:
      if (localStorage.getItem("savings")) {
        let savings = JSON.parse(localStorage.getItem("savings"));
        savings.sort((a, b) => {
          let da = new Date(a.updated_at),
            db = new Date(b.updated_at);
          return db - da;
        });
        this.savings = savings;
        console.log("Savings loaded.");
      } else {
        console.log("No savings found.");
      }
    },
    updateTableContentInStore() {
      console.log("Updating table content in Store");
      this.$store.dispatch("setTableContent", this.createTableContent());
    },
    createTableContent() {
      // Creates table content to be stores in the Store.

      let table_content = [];

      // Loop batches:
      let filename_array = [];
      for (let i = 0; i < this.file_batches.length; i++) {
        // Loop files within batch:
        for (let j = 0; j < this.file_batches[i].files.length; j++) {
          //console.log(this.file_batches);
          filename_array = this.file_batches[i].files[j].filename.split("_");
          for (
            let k = 0;
            k < this.file_batches[i].placement_names.length;
            k++
          ) {
            table_content.push({
              empty: "-",
              site_id: this.file_batches[i].site_id,
              site_name: this.file_batches[i].site_name,
              placement_name: `${filename_array[0]}_${filename_array[1]}_${this.file_batches[i].placement_names[k]}`,
              placement_compatibility: "Display",
              payment_source: "Agency Paid",
              dimensions: this.file_batches[i].files[j].dimension,
              placement_tag_wrapping: "No",
              placement_status: "Active",
              start_date: dayjs().format("MM/DD/YYYY"),
              end_date: dayjs().format("12/31/YYYY"),
              cost_structure: "CPM",
              units: "0",
              rate: "0.0000",
              cost: "0.00",
              opt_this_placement_out_of_ad_blocking: "No",
              assignment_is_active: "Yes",
              ad_is_active: "Yes",
              ad_is_archived: "No",
              ad_start_date:
                dayjs().format(
                  "MM/DD/YYYY hh:mm A"
                ) /* dayjs(this.file_batches[i].ad_start_date).format(
                  "MM/DD/YYYY hh:mm A"
                )*/,
              ad_end_date: dayjs(
                new Date(new Date().getFullYear(), 11, 31, 23, 59)
              ).format(
                "MM/DD/YYYY hh:mm A"
              ) /* dayjs(this.file_batches[i].ad_end_date).format(
                  "MM/DD/YYYY hh:mm A"
                )*/,
              hard_cut_off: "Yes",
              priority: "16",
              creative_type: "Display",
              creative_compatibility: "Display",
              creative_start_date: dayjs(
                this.file_batches[i].creative_start_date
              ).format("MM/DD/YYYY hh:mm A"),
              creative_end_date: dayjs(
                this.file_batches[i].creative_end_date
              ).format("MM/DD/YYYY hh:mm A"),
              creative_assignment_is_active: "Yes",
              creative_assignment_event_tags_enabled: "Yes",
              creative_uses_default_landing_page: "No",
              ad_name: `${filename_array[0]}_${filename_array[1]}_${this.file_batches[i].ad_names[k]}`,
              ad_type: "Standard",
              rotation_type: "Weighted",
              //                weight_calculation_strategy: "",
              //                  this.file_batches[i].weight_calculation_strategy,
              creative_id: this.file_batches[i].files[j].creative_id,
              creative_name:
                this.file_batches[i].files[j].filename.split(".")[0],
              creative_click_through_url: this.file_batches[i].files[
                j
              ].filename.includes(".zip")
                ? ""
                : this.file_batches[i].landing_page,
              landing_page_id: this.landing_page_id,
              landing_page_name: this.landing_page_name,
            });
          }
        }
      }

      return table_content;
    },
    toggle(event) {
      console.log(event);
      this.$refs.op.toggle(event);
    },
    droppedFiles(e) {
      // Store batch ID:
      const id = this.file_batches.length;

      // Append batch:
      this.file_batches.push({
        id: id,
        name: "Untitled",
        files: [],
        display: false,
        unique_dimensions: [],
        placement_names: [],
        ad_names: [],
      });

      // Add files to current batch:
      let unique_dimensions = [];
      let dropped_files = e.dataTransfer.files;
      for (let i = 0; i < dropped_files.length; i++) {
        const filename = dropped_files[i].name;

        // Split the string into an array:
        const filename_array = filename.split("_");

        // Store unique dimensions:
        if (!unique_dimensions.includes(filename_array[1])) {
          unique_dimensions.push(filename_array[1]);
        }

        this.file_batches[id].files.push({
          filename: filename,
          client: filename_array[0],
          dimension: filename_array[1],
          campaign: filename_array[2],
          placements: [],
          creative_id: null,
        });
      }

      // Store unique dimensions:
      this.file_batches[id].unique_dimensions = unique_dimensions;

      // Ensure no landing page ID or name is present:
      this.text_input_landing_page_id = null;
      this.landing_page_name = null;
      this.landing_page_id = null;

      // Ensure no creative ID text is present:
      this.text_input_creative_ids = null;

      // Check if there are multiple creatives sharing file type (.zip or image) and dimension, in which case a warning shall be issued:
      this.warning_creatives_sharing_dimension = false;
      let unique_dimensions_and_extensions = [];
      for (let i = 0; i < dropped_files.length; i++) {
        let filename = dropped_files[i].name;
        let dimension = filename.split("_")[1];
        let extension = filename.substring(filename.lastIndexOf(".") + 1);
        let dimension_extension = dimension + "_" + extension;
        // Store unique combinations of file type and dimension:
        if (!unique_dimensions_and_extensions.includes(dimension_extension)) {
          unique_dimensions_and_extensions.push(dimension_extension);
        } else {
          this.warning_creatives_sharing_dimension = true;
        }
      }

      // Open add new batch popup:
      this.display_add_batch_popup = true;
    },
    relativeDimension(dimension) {
      // Preparing dimensions icon shown in "New creative batch" popup:
      const dimension_array = dimension.split("x");
      const width = dimension_array[0];
      const height = dimension_array[1];

      // Coloring (red if unaccepted dimension):
      let border_styling = "";
      if (this.accepted_dimensions.includes(dimension)) {
        border_styling = " border: 1px solid #3797ed; background: #3797ed10;";
      } else {
        border_styling = " border: 1px solid #f11241; background: #f1124110;";
      }

      const shared_styling =
        " position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border-radius: 2px;";

      if (width > height) {
        let output_height = Math.round((height / width) * 100);
        return `width: 100%; height: ${output_height}%; ${shared_styling} ${border_styling}`;
      }

      let output_width = Math.round((width / height) * 100);
      return `width: ${output_width}%; height: 100%; ${shared_styling} ${border_styling}`;
    },
    dimensionTextStyling(dimension) {
      const shared_styling =
        "margin: 0 5px 0 5px; display: inline-block; font-size: 1em; position: relative; top: -2px;";
      if (this.accepted_dimensions.includes(dimension))
        return `${shared_styling}`;
      return `${shared_styling} text-decoration: line-through; color: #f11241`;
    },
    formatDate(date) {
      return dayjs(date).fromNow();
    },
    deleteBatch(array_index) {
      // Batch name to delete:
      const deleted_batch_name =
        this.file_batches[array_index].files[0].filename.split("_")[0];
      // Delete element:
      this.file_batches.splice(array_index, 1);

      if (this.file_batches.length == 0) {
        this.$store.dispatch("setStartupScreen", true);
      }

      // Issue toast message:
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: `Deleted ${deleted_batch_name}`,
        life: 7000,
      });
    },
    saveNewBatch() {
      // Ensure welcome screen is closed:
      this.$store.dispatch("setStartupScreen", false);
      // Display batch in left menu:
      this.file_batches[this.file_batches.length - 1].display = true;
      // Close popup dialog:
      this.display_add_batch_popup = false;
      // Prepare placement names (remove empty strings):
      while (this.text_input_placements.indexOf("") > -1) {
        this.text_input_placements.splice(
          this.text_input_placements.indexOf(""),
          1
        );
      }
      // Prepare ad names (remove empty strings):
      while (this.text_input_ad_names.indexOf("") > -1) {
        this.text_input_ad_names.splice(
          this.text_input_ad_names.indexOf(""),
          1
        );
      }
      // Append placement names and ad names:
      this.file_batches[this.file_batches.length - 1].placement_names =
        this.text_input_placements;
      this.file_batches[this.file_batches.length - 1].ad_names =
        this.text_input_ad_names;
      this.text_input_placements = [];
      this.text_input_ad_names = [];

      // Add provided landing page:
      this.file_batches[this.file_batches.length - 1].landing_page =
        this.text_input_landing_page;
      this.text_input_landing_page = null;

      //
      this.file_batches[this.file_batches.length - 1].start_date =
        this.selected_ad_period;

      // Set batch name:
      this.file_batches[this.file_batches.length - 1].name =
        this.file_batches[this.file_batches.length - 1].files[0].filename.split(
          "_"
        )[0];

      // Store ID:
      this.file_batches[this.file_batches.length - 1].site_id =
        this.selected_site.site_id;

      // Store site name:
      this.file_batches[this.file_batches.length - 1].site_name =
        this.selected_site.site_name;

      // Reset site (ID and name):
      this.selected_site = null;

      // Store weight calculation strategy:
      this.file_batches[
        this.file_batches.length - 1
      ].weight_calculation_strategy =
        this.selected_weight_calculation_strategy.value;

      // Reset weight calculation strategy:
      this.selected_weight_calculation_strategy = null;

      // Dates:
      /*
      this.file_batches[this.file_batches.length - 1].ad_start_date =
        this.selected_ad_start_date;
      this.file_batches[this.file_batches.length - 1].ad_end_date =
        this.selected_ad_end_date;
*/
      this.file_batches[this.file_batches.length - 1].creative_start_date =
        this.selected_creative_start_date;
      this.file_batches[this.file_batches.length - 1].creative_end_date =
        this.selected_creative_end_date;

      // Reset dates:
      this.selected_ad_start_date = null;
      this.selected_ad_end_date = null;
      this.selected_creative_start_date = null;
      this.selected_creative_end_date = null;

      // Console log for development:
      console.log(this.file_batches);

      // Update table content in Store:
      this.updateTableContentInStore();
      // Issue toast message:
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: "Creative batch added",
        life: 7000,
      });
    },
    cancelNewBatch() {
      // Remove last file batch:
      this.file_batches.pop();
      // Close popup dialog:
      this.display_add_batch_popup = false;
    },
    createNewSaving(filename) {
      this.text_input_saving_name = null;
      this.display_saving_popup = false;

      // Load savings (or create a new empty savings array):
      let savings = [];
      if (localStorage.getItem("savings")) {
        savings = JSON.parse(localStorage.getItem("savings"));
      }

      savings.push({
        name: filename,
        data: this.file_batches,
        created_at: new Date(),
        updated_at: new Date(),
      });

      localStorage.setItem("savings", JSON.stringify(savings));

      this.loadSavingsFromLocalStorage();

      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: "Saved workspace",
        life: 7000,
      });
    },
    replaceSaving(updated_at) {
      // Note: updated_at is used to find the correct saving to update.
      this.text_input_saving_name = null;
      this.display_saving_popup = false;

      // Load savings (or create a new empty savings array):
      let savings = [];
      if (localStorage.getItem("savings")) {
        savings = JSON.parse(localStorage.getItem("savings"));
      }

      savings.forEach((element) => {
        if (element.updated_at == updated_at) {
          element.data = this.file_batches;
          element.updated_at = new Date();
        }
      });

      localStorage.setItem("savings", JSON.stringify(savings));

      this.loadSavingsFromLocalStorage();

      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: "Saved workspace",
        life: 7000,
      });
    },
    loadSaving(data) {
      this.file_batches = data;
      this.display_load_saving_popup = false;
      // Hide startup screen:
      this.$store.dispatch("setStartupScreen", false);
      // Issue toast message:
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: "Loaded workspace",
        life: 7000,
      });
    },
    restartWorkspace() {
      console.log("Restarting");
      this.file_batches = [];
      this.$store.dispatch("setStartupScreen", true);
      //localStorage.setItem("lastname", "Smith");
    },
    downloadSheet(book_type, type) {
      // For supported configurations, see: https://docs.sheetjs.com/docs/api/write-options
      var wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.downloadable_sheet);

      // Prepare filename:
      var m = new Date();
      var current_date_time =
        m.getUTCFullYear() +
        "_" +
        (m.getUTCMonth() + 1) +
        "_" +
        m.getUTCDate() +
        "_" +
        m.getUTCHours() +
        "_" +
        m.getUTCMinutes() +
        "_" +
        m.getUTCSeconds();
      const filename = `AdOps_Optimizer_campaign_${current_date_time}.xlsx`;

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, filename, {
        type: type,
        bookType: book_type,
      });
      console.log(`Downloaded sheet (${type}, ${book_type})`);
    },
    findCreativeIdsInText(text) {
      // Finds creative IDs from text input of the following form:
      /*
      Parfym_300x600_SummerSaleA 174293276
       Parfym_300x600_SummerSaleB 174201442
      */

      if (text != null) {
        // Append creative IDs in batch object:
        var lines = text.split("\n");
        let row_array = null;
        for (var i = 0; i < lines.length; i++) {
          row_array = lines[i].trim().split(" ");

          // Find the creative and add the ID:
          for (
            let j = 0;
            j < this.file_batches[this.file_batches.length - 1].files.length;
            j++
          ) {
            const element =
              this.file_batches[this.file_batches.length - 1].files[j];
            if (element.filename.split(".")[0] == row_array[0]) {
              this.file_batches[this.file_batches.length - 1].files[
                j
              ].creative_id = row_array[1];
            }
          }
        }
      }
    },
    resetCreativeIdsText() {
      this.text_input_creative_ids = null;

      // Reset creative IDs attached to batch files:
      for (
        let j = 0;
        j < this.file_batches[this.file_batches.length - 1].files.length;
        j++
      ) {
        this.file_batches[this.file_batches.length - 1].files[j].creative_id =
          null;
      }
    },
    findLandingPageIdAndLabelInText(text) {
      // Finds the first ID and label in text of the following form:
      /*
      LP_Comingcampaign 33083874
      LP_Default 33084594
      https://www.parfym.se/utm_source=123
      https://www.parfym.se/
      */

      console.log("enjoy ur day man");

      if (text != null) {
        if (
          isNaN(
            text
              .replace(/\n/g, " ")
              .replace(/ +(?= )/g, "")
              .trim()
              .split(" ")[0]
          )
        ) {
          // if the first element of the text contains text
          // Logic used below:
          // 1. Replace newlines with spaces
          // 2. Remove multiple spaces
          // 3. Remove leading and trailing spaces
          // 4. Convert to array by space
          this.landing_page_name = text
            .replace(/\n/g, " ")
            .replace(/ +(?= )/g, "")
            .trim()
            .split(" ")[0];
          this.landing_page_id = text
            .replace(/\n/g, " ")
            .replace(/ +(?= )/g, "")
            .trim()
            .split(" ")[1];
        } else {
          this.landing_page_name = text
            .replace(/\n/g, " ")
            .replace(/ +(?= )/g, "")
            .trim()
            .split(" ")[1];
          this.landing_page_id = text
            .replace(/\n/g, " ")
            .replace(/ +(?= )/g, "")
            .trim()
            .split(" ")[0];
        }
      }
    },
    resetLandingPageIdText() {
      this.text_input_landing_page_id = null;
    },
  },
};
</script>

<style lang="scss">
:root {
  --primary-color: #86698e;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@font-face {
  font-family: customFont;
  src: url(/Nunito-Regular.woff);
}

#app {
  font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #04141a;
}

body {
  margin: 0;
  background: #f9f3ee;
}

.top_menu {
  height: 70px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 40px;
  background: #fff;
}

.logotype {
  font-size: 1.2em;
  font-weight: 700;
  margin-right: 20px;
}

.menu_button {
  margin-left: 10px;
  font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.download_button {
  margin-left: auto;
  font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.left_menu_and_main_content_wrapper {
  display: grid;
  grid-template-columns: 300px auto;
}

.left_menu {
  background: #fff;
  width: 300px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  color: #cfcfcf;
  position: relative;
  overflow-y: auto;
}

.batch_card {
  background: #f9e795;
  position: relative;
  margin: 15px 10px 0 10px;
  border-radius: 15px;
  padding: 10px;
  text-align: left;
  color: #4c4c4c;
}

.batch_header {
  font-weight: 700;
  margin: 5px;
  color: #000;
}

.batch_file {
  font-size: 0.65em;
  margin: 0;
}

.left_menu_upload_area {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.drop_files_help_link {
  position: absolute;
  bottom: 40px;
  color: #888;
  font-weight: 700;
  cursor: pointer;
}

.drop_files_help_link:hover {
  color: rgb(0, 174, 255);
}

.upload_area_card {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #f0ecdc;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.main_content {
  width: 100%;
  height: calc(100vh - 70px);
  overflow: hidden;
}

/* Add new batch popup: */

.add_batch_popup {
  font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
  min-width: 600px;
}

.text_input_wrapper {
  padding: 0 10px 10px 10px;
}

.text_input_field {
  font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
}

.dropdown_input_field > * {
  font-family: customFont, Avenir, Helvetica, Arial, sans-serif;
}

.buttom_row {
  margin-top: 50px;
  text-align: right;
}

/* Load savings popup: */

.load_savings_row {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  display: grid;
  grid-template-columns: 500px auto;
}
.load_savings_row:hover {
  background: #ecf5f5;
  cursor: pointer;
}

/* Transition (text input field): */

.field-input-enter-active {
  transition: all 500ms ease-out;
}

.field-input-enter-from,
.field-input-leave-to {
  opacity: 0;
  transform: translateX(-5px);
}

/* Transition (batch card): */

.batch-card-leave-active,
.batch-card-enter-active {
  transition: all 500ms ease-out;
}

.batch-card-enter-from,
.batch-card-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
</style>
